import React, { useEffect, useState } from 'react';
import AUTH_BASE_URL from '../../../util/AUTH_BASE_URL';
import KanbanBoard from './KanBan';
import Wrapper from "../../global/Wrapper";
import Team from './Team';  // Import the Team component
import TeamStatus from './TeamStatus';
import { DragDropContext } from 'react-beautiful-dnd';

const Planning = () => {
    const [data, setData] = useState(null);
    const [stateColumnId, setStateColumnId] = useState(null);
    const [assigneeColumnId, setAssigneeColumnId] = useState(null);
    const [uniqueAssignees, setUniqueAssignees] = useState([]);  // Unique assignees
    const [selectedAssignee, setSelectedAssignee] = useState(null);  // State to track the active assignee
    const [columns, setColumns] = useState({});  // Columns for the Kanban board
    const [inProgressTasksCount, setInProgressTasksCount] = useState(0);  // Track in-progress tasks count
    const [editing, setEditing] = useState(false);
    // Fetch data from the backend
    const fetchData = async () => {
        try {
            const response = await fetch(`${AUTH_BASE_URL}projects/planning/data`, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);

            const stateColumn = result.columns.find(col => col.title.toLowerCase().includes('state'));
            const assigneeColumn = result.columns.find(col => col.title.toLowerCase().includes('assignee'));
            setStateColumnId(stateColumn?.id);
            setAssigneeColumnId(assigneeColumn?.id);

            const dynamicColumns = {};
            result.columns.forEach(col => {
                dynamicColumns[col.id] = { title: col.title, options: col.options, state: col.state || [] };
            });
            setColumns(dynamicColumns);

            const uniqueAssigneesList = getUniqueAssignees(result.rows, assigneeColumn?.id);
            setUniqueAssignees(uniqueAssigneesList);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Get unique assignees from tasks
    const getUniqueAssignees = (tasks, assigneeColumnId) => {
        const uniqueAssignees = new Set();

        tasks.forEach(task => {
            const assigneeCell = task.cells.find(cell => cell.columnId === assigneeColumnId);
            if (assigneeCell && assigneeCell.value) {
                const assignees = assigneeCell.value.split(',').map(assignee => assignee.trim());
                assignees.forEach(assignee => uniqueAssignees.add(assignee));
            }
        });

        return Array.from(uniqueAssignees).map(assignee => ({
            name: assignee,
            avatar: `https://api.adorable.io/avatars/40/${assignee}.png`  // Mock avatar URL
        }));
    };

    // Calculate in-progress tasks count for the selected assignee
    const calculateInProgressTasks = () => {
        if (!data || !data.rows || !columns) return;

        const stateColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "State");
        const assigneeColumnId = Object.keys(columns).find(columnId => columns[columnId].title === "Assignee");

        const inProgressTasks = data.rows.filter(row => {
            const stateCell = row.cells.find(cell => cell.columnId === parseInt(stateColumnId));
            const assigneeCell = row.cells.find(cell => cell.columnId === parseInt(assigneeColumnId));

            return stateCell?.value.toLowerCase() === "in progress" && assigneeCell?.value === selectedAssignee;
        });

        setInProgressTasksCount(inProgressTasks.length);
    };

    // When a teammate is selected, calculate their tasks
    useEffect(() => {
        if (selectedAssignee) {
            calculateInProgressTasks();
        }
    }, [selectedAssignee, data, columns]);

    // Filter out selected teammate's "In Progress" tasks from Kanban board
    const getFilteredKanbanData = () => {
        if (!data || !data.rows) return data;

        // Filter out only the selected assignee's "In Progress" tasks from Kanban board
        const filteredRows = data.rows.filter(row => {
            const assigneeCell = row.cells.find(cell => cell.columnId === assigneeColumnId);
            const stateCell = row.cells.find(cell => cell.columnId === stateColumnId);

            // Keep all tasks except the "In Progress" tasks of the selected assignee
            return !(assigneeCell?.value === selectedAssignee && stateCell?.value.toLowerCase() === 'in progress');
        });

        return { ...data, rows: filteredRows };
    };

    const onDragEnd = async (result) => {
        const { destination, draggableId } = result;

        if (!destination) return;

        const draggedTask = data.rows.find(task => String(task.id) === draggableId);

        if (!draggedTask) return;

        const stateCell = draggedTask.cells.find(cell => cell.columnId === stateColumnId);
        const assigneeCell = draggedTask.cells.find(cell => cell.columnId === assigneeColumnId);

        const droppedOnTeammate = uniqueAssignees.find(assignee => assignee.name === destination.droppableId);

        if (droppedOnTeammate && assigneeCell.value !== destination.droppableId) {
            // Optimistically update the UI
            const previousAssignee = assigneeCell.value;
            assigneeCell.value = destination.droppableId;

            const updatedTask = { ...draggedTask, cells: [...draggedTask.cells] };
            setData(prevData => ({
                ...prevData,
                rows: prevData.rows.map(task => task.id === draggedTask.id ? updatedTask : task),
            }));

            try {
                console.log('138', {
                    taskId: draggedTask.id,
                    newAssignee: destination.droppableId,
                    columnId: assigneeColumnId,
                })
                await fetch(`${AUTH_BASE_URL}projects/planning/update-state`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        taskId: draggedTask.id,
                        newAssignee: destination.droppableId,
                        columnId: assigneeColumnId,
                    }),
                });
            } catch (error) {
                console.error('Error updating assignee in Smartsheet:', error);
                // Rollback the UI if the request fails
                assigneeCell.value = previousAssignee;
                setData(prevData => ({
                    ...prevData,
                    rows: prevData.rows.map(task => task.id === draggedTask.id ? draggedTask : task),
                }));
            }
        } else if (stateCell.value !== destination.droppableId) {
            const previousState = stateCell.value;
            stateCell.value = destination.droppableId;

            const updatedTask = { ...draggedTask, cells: [...draggedTask.cells] };
            setData(prevData => ({
                ...prevData,
                rows: prevData.rows.map(task => task.id === draggedTask.id ? updatedTask : task),
            }));

            try {
                console.log('172', {
                    taskId: draggedTask.id,
                    newState: destination.droppableId,
                    columnId: stateColumnId,
                })
                await fetch(`${AUTH_BASE_URL}projects/planning/update-state`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        taskId: draggedTask.id,
                        newState: destination.droppableId,
                        columnId: stateColumnId,
                    }),
                });
            } catch (error) {
                console.error('Error updating task state in Smartsheet:', error);
                stateCell.value = previousState;
                setData(prevData => ({
                    ...prevData,
                    rows: prevData.rows.map(task => task.id === draggedTask.id ? draggedTask : task),
                }));
            }
        }
    };

    let borderStyle = {
        borderWidth: '4px',
        borderStyle: 'solid',
        borderRadius: '8px'
    };

    // Border styling logic for the selected assignee
    const assignee = selectedAssignee;
    console.log('192', selectedAssignee)
    const getGradientColors = (assignee) => {
        const lowerAssignee = selectedAssignee?.toLowerCase();
        console.log(assignee, lowerAssignee)
        if (lowerAssignee?.includes('juju')) {
            return ['#f02cf0', '#4a276c'];
        } else if (lowerAssignee?.includes('hannah')) {
            return ['#07d707', '#dff257'];
        } else if (lowerAssignee?.includes('rich')) {
            return ['#f4f43b', 'orange'];
        } else if (lowerAssignee?.includes('jackie')) {
            return ['#e3282f', '#cc189c'];
        } else if (lowerAssignee?.includes('tony')) {
            return ['rgb(49,108,234)', '#27dc78'];
        } else if (lowerAssignee?.includes('vidya')) {
            return ['#f3ac2a', '#ca1919'];
        } else if (lowerAssignee?.includes('dave')) {
            return ['#f42dc9', '#ff0071'];
        } else {
            return ['#a0a0a0', '#0d0d0d'];  // Default gradient (gray)
        }
    };

    const [startColor, endColor] = getGradientColors(assignee?.toLowerCase());
    return (
        <Wrapper>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="main-content pt-0">
                    <div className="container-fluid">
                        <div className="inner-body users">
                            <div
                                className="page-header d-flex justify-content-between align-items-center flex-direction-column"
                                style={{
                                    boxShadow: selectedAssignee ? '0px 20px 45px rgba(0, 0, 0, 0.1)' : 'none',
                                    borderImage: selectedAssignee === assignee
                                        ? `linear-gradient(${startColor}, ${endColor}) 1`
                                        : 'none',
                                    borderLeft: selectedAssignee ? '20px solid' : '0',
                                    flexDirection: 'column',
                                    backgroundColor: selectedAssignee ? '#ffffff' : '#f2f2f2',
                                    padding: '40px',
                                    paddingBottom: selectedAssignee ? '40px' : '0',
                                    marginBottom: '0'
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '10px' }}>
                                    <h2 className="main-content-title tx-24 mg-b-5">
                                        {selectedAssignee ? `${selectedAssignee}'s In Progress Tasks (${inProgressTasksCount})` : "Project Planning"}
                                    </h2>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                                    <Team
                                        assignees={uniqueAssignees}
                                        selectedAssignee={selectedAssignee}
                                        onSelectAssignee={setSelectedAssignee}
                                    />
                                </div>
                                <TeamStatus
                                    selectedAssignee={selectedAssignee}
                                    data={data}
                                    columns={columns}
                                    fetchData={fetchData}
                                />
                            </div>

                            {!data ? <div>Loading...</div> : (
                                <div className="row row-sm">
                                    <KanbanBoard
                                        data={getFilteredKanbanData()}  // Pass the filtered Kanban data here
                                        onDragEnd={onDragEnd}
                                        stateColumnId={stateColumnId}
                                        assigneeColumnId={assigneeColumnId}
                                        fetchData={fetchData}
                                        setData={setData}
                                        selectedAssignee={selectedAssignee}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </Wrapper>
    );
};

export default Planning;
